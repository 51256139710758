import http from "../../index";

interface  LoginQRCode {
    ticket: string;
    expireSeconds: number;
    url: string;
    qrUrl: string
}

export const loginQRCode = (appid: string) =>{
    return http<LoginQRCode>({
        url: `/ocean-weixin/scan/mp/create/ticket/${appid}`,
        method: "POST"
    })
}
