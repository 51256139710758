import React from 'react';
import Router, { rootRouter } from './routers'
import { Navbar, Text, Avatar, Dropdown, Input } from "@nextui-org/react";
import INavbar from "./components/INavbar";
import './App.css'
import {Layout} from "./components/INavbar/Layout";
import { Box } from './components/INavbar/Box';

//
// const App = () => {
//   return (
//       <div>
//           <Link to="/about">about</Link>
//           <Link to="/">home</Link>
//         <Router></Router>
//       </div>
//   );
// }

export default function App() {
    return (
        <div className="scroll-container">
            <INavbar></INavbar>
            <Layout>
                <Box as="main">
                    <Router></Router>
                </Box>
            </Layout>
        </div>
    );
}
