// @flow
import * as React from 'react';
import {
    Modal,
    Button,
    Image,
    Text,
    Link,
    Loading,
    Card,
    Grid,
    Divider,
    Input,
    Spacer,
    useInput
} from "@nextui-org/react";
import {loginQRCode} from "../../http/api/qr/LoginQRCode";
import {useEffect, useState} from "react";
import { Flex } from '../styles/flex';
import {CheckIcon} from "../Icons/CheckIcon";
import {Box} from "../styles/box";
import {FormElement} from "@nextui-org/react/types/input/input-props";
import {ServiceConsult, serviceConsult} from "../../http/api/join/ServiceConsult";

type Props = {
    type: "JOIN" | "SERVICE_MINI" | "SERVICE_MP" | "SERVICE_PC" | "";
    initVisible?:boolean;
    changeModal: ()=> void;
};

type colorType = "default" | "primary" | "secondary" | "success" | "warning" | "error";


export default function ModalQRCode(props:Props) {
    const {type} = props;
    let intervalId: NodeJS.Timeout;
    const {initVisible = false, changeModal} = props;

    const [qrUrl, setQrUrl] = useState('');

    //提交的数据
    const [formData, setFormData] = useState<ServiceConsult>(
        {type: type, name:"", phone: ""}
    )

    //按钮是否禁用
    const [disabledStatus, setDisabledStatus] = useState(true);

    //按钮加载状态
    const [loadingStatus, setLoadingStatus] = useState(false)

    //是否提交过
    const [submitStatus, setSubmitStatus] = useState(false)

    const { value, reset, bindings } = useInput("");



    useEffect(() => {
        if (initVisible){
            reqLoginQRCode()
        }else {
            // clearInterval(intervalId)
        }

        return () => {
            // clearInterval(intervalId);
            setSubmitStatus(false)
            setDisabledStatus(true)
            reset()
        };
    }, [initVisible]); // 监听data的变化

    const closeHandler = () => {
        changeModal()
    };


    //数据改变
    const handleChange = ( event:React.ChangeEvent<FormElement>) =>{
        setFormData((prevState)=>(
            {
                ...prevState,
                name: event.target.value
            }
        ))

        if (event.target.value.trim().length > 0 && validatePhone(value)){
            setDisabledStatus(false)
        }else {
            setDisabledStatus(true)
        }

    }


    const validatePhone = (value: string) => {
        return value.match(/^1[3456789]\d{9}$/);
    };

    const helper = React.useMemo(() => {
        if (!value)
            return {
                text: "",
                color: ""  as colorType,
            };
        const isValid = validatePhone(value);
        if (isValid){
            if (formData.name.trim().length > 0){
                setDisabledStatus(false)
            }
            setFormData((prevState)=>(
                {
                    ...prevState,
                    phone: value
                }
            ))
        }else {
            setDisabledStatus(true)
        }
        return {
            text: isValid ? "手机号格式正确" : "手机号格式错误",
            color: isValid ? "success"  as colorType: "error"  as colorType,
        };
    }, [value]);

    const reqLoginQRCode = () =>{
        loginQRCode("wx2d59289fe2ca0cd3").then((res)=>{
            console.log("res", res)
            setQrUrl(res.data.qrUrl)
            // intervalId = setInterval(()=>{
            //     pollingQRCode(res.data.ticket).then((resd)=>{
            //         console.log("resd", resd)
            //     })
            // },1500)
        })
    }

    const submit = async () =>{
        console.log("formData", formData)
        setLoadingStatus(true)
        await serviceConsult('wx2d59289fe2ca0cd3', formData).then(res=>{
            setSubmitStatus(true)
        }).finally(()=>{
            setLoadingStatus(false)
        })
    }
    // @ts-ignore
    return (
        <>
            <Modal
                width="500px"
                   css={{padding:"10px"}}
                   noPadding open={initVisible} onClose={closeHandler}>
                <Modal.Header
                    css={{ 'alignItems': 'center', zIndex: "$1",}}
                >
                    {/*<Flex direction={'column'} align={'center'}>*/}
                    {/*    <Text span css={{color: '$blue600'}}>*/}
                    {/*        Welcome aboard.*/}
                    {/*    </Text>*/}
                    {/*    <Text h2>微信扫码 or 联系方式</Text>*/}
                    {/*</Flex>*/}
                </Modal.Header>
                <Modal.Body
                    css={{padding:"0 10px"}}
                >
                    <Card.Header>
                        <Grid.Container>
                            <Grid xs={12}>
                                <Flex
                                    css={{width: '100%'}}
                                    align={'center'}
                                    justify={'between'}
                                    direction={'row'}
                                >
                                    <Flex direction={'column'} align={'start'}>
                                        <Text span h5>
                                            欢迎咨询
                                        </Text>
                                        <Text size="$xs" css={{color: '$accents8'}}>
                                            请填写下方表单，以方便我们联系您
                                        </Text>
                                    </Flex>
                                    <Flex>
                                        {qrUrl?
                                            <Image
                                                src={qrUrl}
                                                width={80}
                                                height={80}
                                            />
                                            :
                                            <Flex style={{width:"80px", height:"80px"}} direction={'column'} align={'center'} justify={'center'}>
                                                <Loading color="primary" textColor="primary">
                                                </Loading>
                                            </Flex>
                                        }
                                    </Flex>
                                </Flex>
                            </Grid>
                        </Grid.Container>
                    </Card.Header>
                    <Divider
                        css={{mb: '$6', }}
                    />

                    <Spacer y={.3} />
                        <Input type="text"
                               onChange={(e)=>handleChange( e)}
                               css={{mb: '$6', }} clearable
                               labelPlaceholder="姓名"
                               initialValue="" />

                        <Spacer y={.3} />
                        <Input
                            {...bindings}
                            clearable
                            shadow={false}
                            onClearClick={reset}
                            status={helper.color}
                            color={helper.color}
                            helperColor={helper.color}
                            helperText={helper.text}
                            labelPlaceholder="手机号"
                            css={{mb: '$10', }}
                        />
                        <Button disabled={ (loadingStatus || submitStatus) || disabledStatus} onClick={submit} css={{mt: '$7', mb: '$12'}}>
                            {loadingStatus?
                                <Loading type="points-opacity" color="currentColor" size="sm" />
                                :
                                submitStatus? <CheckIcon />: "提交"
                            }
                        </Button>

                        <Box as={'ul'}>
                            <Text size="$xs" span css={{color: '$accents8'}}>
                                48小时内回复
                            </Text>
                        </Box>
                </Modal.Body>
            </Modal>
        </>
    );
}
