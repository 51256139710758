import React from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import './styles/globals.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {AliveScope} from "react-activation";
import {NextUIProvider} from "@nextui-org/react";
import {RecoilRoot} from "recoil";


const root = document.getElementById('root')

if (root) {
    createRoot(root).render(
        <BrowserRouter>
            <AliveScope>
                <RecoilRoot>
                    <NextUIProvider>
                        <App />
                    </NextUIProvider>
                </RecoilRoot>
            </AliveScope>
        </BrowserRouter>,
    )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
