import React, { ReactNode } from 'react';
import { Box } from "./Box";
import {Content} from "./Content";
//
// export const Layout = ({ children }: { children: ReactNode }) => (
//     <Box
//         css={{maxW: "100%", px: "$12", mt: "$8", "@xsMax": {px: "$10"}}}
//     >
//         {children}
//         {/*<Content />*/}
//     </Box>
// );


interface LayoutProps {
    children: React.ReactNode;
}

export const Layout = ({children}: LayoutProps) => (
    <Box
        css={{
            maxW: '100%',
            background: '$background',
        }}
    >
        {children}
    </Box>
);
