// import {Button, Divider, Text} from '@nextui-org/react';
import {Text} from '@nextui-org/react';
import React from 'react';
import {Box} from '../styles/box';
import {Flex} from '../styles/flex';

export const Statistics = () => {
   return (
      <>
         <Box
            css={{
               pt: '$20',
               pb: '$16',
               px: '$6',
            }}
         >
            {/*<Flex direction={'column'} align={'center'}>*/}
            {/*   <Text*/}
            {/*      h3*/}
            {/*      css={{*/}
            {/*         textAlign: 'center',*/}
            {/*      }}*/}
            {/*   >*/}
            {/*      定制开发，如此简单*/}
            {/*   </Text>*/}
            {/*   <Text*/}
            {/*      span*/}
            {/*      css={{*/}
            {/*         maxWidth: '800px',*/}
            {/*         textAlign: 'center',*/}
            {/*      }}*/}
            {/*   >*/}
            {/*      一千个读者就有一千个哈姆雷特*/}
            {/*   </Text>*/}
            {/*</Flex>*/}
            <Flex
               direction={'row'}
               wrap={'wrap'}
               justify={'center'}
               css={{
                  'gap': '4rem',
                  'pt': '$16',
                  '@sm': {
                     gap: '10rem',
                  },
               }}
            >
               <Flex direction={'column'}>
                  <Text h2 css={{color: '$blue600'}}>
                     5000+
                  </Text>
                  <Text span css={{textAlign: 'center'}} weight={'medium'}>
                     用户
                  </Text>
               </Flex>
               <Flex direction={'column'}>
                  <Text h2 css={{color: '$blue600'}}>
                     100+
                  </Text>
                  <Text span css={{textAlign: 'center'}} weight={'medium'}>
                     覆盖行业
                  </Text>
               </Flex>
               <Flex direction={'column'}>
                  <Text h2 css={{color: '$blue600'}}>
                     350+
                  </Text>
                  <Text span css={{textAlign: 'center'}} weight={'medium'}>
                     安全运行
                  </Text>
               </Flex>
               {/*<Flex direction={'column'}>*/}
               {/*   <Text h2 css={{color: '$blue600'}}>*/}
               {/*      7*/}
               {/*   </Text>*/}
               {/*   <Text span css={{textAlign: 'center'}} weight={'medium'}>*/}
               {/*      无条件退款*/}
               {/*   </Text>*/}
               {/*</Flex>*/}
            </Flex>
         </Box>

         {/*<Divider*/}
         {/*   css={{position: 'absolute', inset: '0p', left: '0', mt: '$5'}}*/}
         {/*/>*/}
      </>
   );
};
