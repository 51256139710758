import {Button, Divider, Text} from '@nextui-org/react';
import React from 'react';
import {BoxIcon} from '../Icons/BoxIcon';
import {Box} from '../styles/box';
import {Flex} from '../styles/flex';

export const Faq = () => {
   return (
      <>
         <Flex
            css={{
               py: '$20',
               gap: '$18',
               px: '$6',
            }}
            direction={'column'}
         >
            <Flex align={'center'} direction={'column'}>
               <Text span css={{color: '$blue600'}}>
                  FAQ
               </Text>
               <Text h2>我有问题要问</Text>
               <Text
                  span
                  css={{
                     maxWidth: '700px',
                     color: '$accents8',
                     textAlign: 'center',
                  }}
               >
                  一站式指南，了解微信生态系统中的应用开发和网站建设
               </Text>
            </Flex>

            <Flex
               css={{
                  'gap': '$10',
                  '@lg': {
                     px: '$64',
                  },
               }}
               direction={'column'}
            >
               <Flex css={{gap: '$5'}} justify={'center'}>
                  <BoxIcon />
                  <Flex direction={'column'} css={{gap: '$3'}}>
                     <Text h3>
                        什么是微信小程序，它与传统应用程序有何不同？
                     </Text>
                     <Text
                        span
                        css={{
                           color: '$accents8',
                        }}
                     >
                        微信小程序是一种运行在微信客户端内的应用程序，用户无需下载安装即可直接使用。它提供了丰富的功能，包括在线购物、社交分享、娱乐游戏等。小程序可以通过微信搜索、扫描二维码或朋友分享进入。
                        微信小程序在微信客户端内运行，因此可以在不同的操作系统平台上使用，包括iOS和Android系统。这意味着开发者只需开发一次小程序，就可以在多个平台上进行部署和使用。
                     </Text>
                  </Flex>
               </Flex>

               <Flex css={{gap: '$5'}} justify={'center'}>
                  <BoxIcon />
                  <Flex direction={'column'} css={{gap: '$3'}}>
                     <Text h3>
                        微信小程序与微信公众号之间有什么区别和联系？
                     </Text>
                     <Text
                        span
                        css={{
                           color: '$accents8',
                        }}
                     >
                        微信小程序和微信公众号都是基于微信平台的应用，但它们的功能和使用场景有所不同。微信小程序更注重应用的轻量化和即时性，适用于一次性任务、实时互动等场景；而微信公众号更侧重于信息传播和用户互动，适用于新闻、推送、营销等场景。它们可以通过互相跳转和嵌入的方式实现联动和互补。
                     </Text>
                  </Flex>
               </Flex>

               <Flex css={{gap: '$5'}} justify={'center'}>
                  <BoxIcon />
                  <Flex direction={'column'} css={{gap: '$3'}}>
                     <Text h3>
                        微信公众号与微信小程序在功能和使用场景上有何差异？
                     </Text>
                     <Text
                        span
                        css={{
                           color: '$accents8',
                        }}
                     >
                        微信公众号和微信小程序在功能和使用场景上有一些差异。微信公众号适合信息传播、用户互动和营销推广等场景，可以通过图文消息、语音消息、菜单等方式与用户进行交互。微信小程序更注重即时性和轻量化，适合一次性任务、实时互动、小工具等场景，可以提供更接近原生应用的体验。
                     </Text>
                  </Flex>
               </Flex>

               <Flex css={{gap: '$5'}} justify={'center'}>
                  <BoxIcon />
                  <Flex direction={'column'} css={{gap: '$3'}}>
                     <Text h3>
                        如何将微信公众号与网站建站相结合，以增强用户体验？
                     </Text>
                     <Text
                        span
                        css={{
                           color: '$accents8',
                        }}
                     >
                        将微信公众号与网站建站相结合可以增强用户体验和扩展业务。可以在公众号中提供网站的链接，使用户可以方便地跳转到网站进行更深入的浏览和交互。同时，可以在网站中嵌入公众号的内容或功能，如关注公众号、分享到公众号等，以增加用户粘性和互动性。
                     </Text>

                  </Flex>
               </Flex>
            </Flex>
         </Flex>

         {/*<Divider*/}
         {/*   css={{position: 'absolute', inset: '0p', left: '0', mt: '$5'}}*/}
         {/*/>*/}
      </>
   );
};
