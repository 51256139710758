import React from 'react';
import {Card} from "@nextui-org/react";
import { Image } from "@nextui-org/react";
import {Join} from "../../pages/Join";
interface Props {
    src?: string;
    children?: React.ReactNode;
    width?: number;
    height?: number;
}
function QRCode(props: Props) {
    const {src, children, width = 200, height = 200} = props
    return (
        <>
            <Card>
                <Card.Body>
                    {
                        children
                    }
                    {
                        src?
                            <Image
                                width={width}
                                height={height}
                                src={src}
                                alt="Default Image"
                                objectFit="cover"
                            />:''
                    }
                </Card.Body>
            </Card>
        </>
    );
}

export default QRCode;
