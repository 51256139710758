// @flow
import * as React from 'react';
import {Flex} from "../../components/styles/flex";
import {Button, Card, Divider, Grid, Input, Loading, Spacer, Text, useInput} from "@nextui-org/react";
import {Box} from "../../components/styles/box";
import {CheckIcon} from "../../components/Icons/CheckIcon";
import {joinUs, JoinUs} from "../../http/api/join/JoinUs";
import {ChangeEvent, useEffect, useState} from "react";
import {FormElement} from "@nextui-org/react/types/input/input-props";
import {SimpleColors} from "@nextui-org/react/types/utils/prop-types";

type Props = {

};

type colorType = "default" | "primary" | "secondary" | "success" | "warning" | "error";

export const Join = (props: Props) => {
    //提交的数据
    const [formData, setFormData] = useState<JoinUs>(
        {name:"", phone: ""}
    )

    //按钮是否禁用
    const [disabledStatus, setDisabledStatus] = useState(true);

    //按钮加载状态
    const [loadingStatus, setLoadingStatus] = useState(false)

    //是否提交过
    const [submitStatus, setSubmitStatus] = useState(false)


    //数据改变
    const handleChange = ( event:React.ChangeEvent<FormElement>) =>{
        setFormData((prevState)=>(
            {
                ...prevState,
                name: event.target.value
            }
        ))

        if (event.target.value.trim().length > 0 && validatePhone(value)){
            setDisabledStatus(false)
        }else {
            setDisabledStatus(true)
        }

    }

    const { value, reset, bindings } = useInput("");

    const validatePhone = (value: string) => {
        return value.match(/^1[3456789]\d{9}$/);
    };

    const helper = React.useMemo(() => {
        if (!value)
            return {
                text: "",
                color: ""  as colorType,
            };
        const isValid = validatePhone(value);
        if (isValid){
            if (formData.name.trim().length > 0){
                setDisabledStatus(false)
            }
            setFormData((prevState)=>(
                {
                    ...prevState,
                    phone: value
                }
            ))
        }else {
            setDisabledStatus(true)
        }
        return {
            text: isValid ? "手机号格式正确" : "手机号格式错误",
            color: isValid ? "success"  as colorType: "error"  as colorType,
        };
    }, [value]);

    //发送请求
    const reqJoinUs = async () =>{
        setLoadingStatus(true)
        await joinUs('wx2d59289fe2ca0cd3', formData).then(res=>{
            setSubmitStatus(true)
        }).finally(()=>{
            setLoadingStatus(false)
        })
    }

    return (
        <>
            <Flex
                css={{py: '$20', gap: '1rem', px: '$6'}}
                justify={'center'}
                wrap={'wrap'}
                direction={'column'}
                align={'center'}
            >
                <Flex direction={'column'} align={'center'}>
                    <Text span css={{color: '$blue600'}}>
                        Welcome aboard.
                    </Text>
                    <Text h2>期待您的加入</Text>
                </Flex>

                <Flex
                    css={{gap: '2rem', width: '100%'}}
                    wrap={'wrap'}
                    justify={'center'}
                >
                    <Card css={{p: '$6', mw: '700px'}}>
                        <Card.Header>
                            <Grid.Container css={{pl: '$6'}}>
                                <Grid xs={12}>
                                    <Text h4 css={{lineHeight: '$xs'}}>
                                        联系方式
                                    </Text>
                                </Grid>
                                <Grid xs={12}>
                                    <Text size="$xs" css={{color: '$accents8'}}>
                                        请填写下方表单，以方便我们联系您
                                    </Text>
                                </Grid>
                            </Grid.Container>
                        </Card.Header>
                        <Divider
                        css={{mb: '$6', }}
                        />

                        <Card.Body css={{py: '$2'}}>
                            <Input type="text"
                                   onChange={(e)=>handleChange( e)}
                                   css={{mb: '$6', }} clearable label="姓名" placeholder="请输入您的姓名" initialValue="" />

                            <Spacer y={.3} />
                            <Input
                                {...bindings}
                                clearable
                                shadow={false}
                                onClearClick={reset}
                                status={helper.color}
                                color={helper.color}
                                helperColor={helper.color}
                                helperText={helper.text}
                                label="手机号"
                                placeholder="请输入您的手机号"
                                css={{mb: '$10', }}
                            />
                            <Button disabled={ (loadingStatus || submitStatus) || disabledStatus} onClick={reqJoinUs} css={{mt: '$7', mb: '$12'}}>
                                {loadingStatus?
                                    <Loading type="points-opacity" color="currentColor" size="sm" />
                                    :
                                    submitStatus? <CheckIcon />: "提交"
                                }
                            </Button>

                            <Box as={'ul'}>
                                <Flex
                                    as={'li'}
                                    css={{py: '$2', gap: '$2'}}
                                    align={'center'}
                                >
                                    <Text size="$xs" span css={{color: '$accents8'}}>
                                        7 个工作日内回复
                                    </Text>
                                </Flex>
                            </Box>
                        </Card.Body>
                    </Card>

                </Flex>
            </Flex>

            {/*<Divider*/}
            {/*   css={{position: 'absolute', inset: '0p', left: '0', mt: '$5'}}*/}
            {/*/>*/}
        </>
    );
};
