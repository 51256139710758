import {Navbar, Link, Text, Avatar, Dropdown, Input, Switch, useTheme} from "@nextui-org/react";
import { Layout } from "./Layout";
import { useNavigate } from 'react-router-dom';

import { AcmeLogo } from "./AcmeLogo";
import {SearchIcon} from "./SearchIcon";
import {useState} from "react";

export default function INavbar() {

    const navigate = useNavigate();

    const [selected, setSelected] = useState(false);

    const collapseItems = [
        {
            title: "首页",
            path: "/"
        },{
            title: "加入我们",
            path: "/join"
        },
    ];

    // const collapseItems = [
    //     "Profile",
    //     "Dashboard",
    //     "Activity",
    //     "Analytics",
    //     "System",
    //     "Deployments",
    //     "My Settings",
    //     "Team Settings",
    //     "Help & Feedback",
    //     "Log Out",
    // ];
    const [active, setActive] = useState(0);

    const changeActive = (index: number, path: string) =>{
        setActive(index)
        navigate(path)
    }

    const onScrollPositionChange = (scrollPosition:number) =>{
        console.log("scrollPosition", scrollPosition)
    }

    return (
        //variant="floating"
        <Navbar onScrollPositionChange={onScrollPositionChange} maxWidth="fluid" variant="sticky">
            <Navbar.Toggle  onChange={(selected)=>{
                setSelected(selected as boolean)
                console.log("change", selected)}}
                           showIn="xs" />
            <Navbar.Brand

            >
                {/*<AcmeLogo />*/}
                <Text b color="inherit" >
                    予技谷
                </Text>
            </Navbar.Brand>
            <Navbar.Content
                enableCursorHighlight
                activeColor="primary"
                hideIn="xs"
                gap="$12"
                css={{
                    "@xs": {
                        w: "100%",
                        jc: "center",
                    },
                }}
                // variant="highlight-rounded"
            >
                <Navbar.Link
                    isActive = {active == 0}
                    onClick={()=>{
                        changeActive(0, '/')
                    }}>
                    首页
                </Navbar.Link>

                <Navbar.Link
                    isActive = {active == 1}
                    onClick={()=>{
                        changeActive(1, '/join')
                    }} >
                    加入我们
                </Navbar.Link>

                {/*<Navbar.Link*/}
                {/*    isActive = {active == 1}*/}
                {/*    onClick={()=>{*/}
                {/*    changeActive(1, '/about')*/}
                {/*}} >*/}
                {/*    关于我们*/}
                {/*</Navbar.Link>*/}

                {/*<Navbar.Item*/}
                {/*    css={{*/}
                {/*        "@xsMax": {*/}
                {/*            w: "10%",*/}
                {/*            jc: "center",*/}
                {/*        },*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <Input*/}
                {/*        clearable*/}
                {/*        contentLeft={*/}
                {/*            <SearchIcon fill="var(--nextui-colors-accents6)" size={16} />*/}
                {/*        }*/}
                {/*        contentLeftStyling={false}*/}
                {/*        css={{*/}
                {/*            w: "100%",*/}
                {/*            "@xsMax": {*/}
                {/*                mw: "300px",*/}
                {/*            },*/}
                {/*            "& .nextui-input-content--left": {*/}
                {/*                h: "100%",*/}
                {/*                ml: "$4",*/}
                {/*                dflex: "center",*/}
                {/*            },*/}
                {/*        }}*/}
                {/*        placeholder="Search..."*/}
                {/*    />*/}
                {/*</Navbar.Item>*/}
            </Navbar.Content>
            {/*<Navbar.Content*/}
            {/*    css={{*/}
            {/*        "@xs": {*/}
            {/*            jc: "flex-end",*/}
            {/*        },*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <Dropdown placement="bottom-right">*/}
            {/*        <Navbar.Item>*/}
            {/*            <Dropdown.Trigger>*/}
            {/*                <Avatar*/}
            {/*                    bordered*/}
            {/*                    as="button"*/}
            {/*                    color="primary"*/}
            {/*                    size="md"*/}
            {/*                    src="https://i.pravatar.cc/150?u=a042581f4e29026704d"*/}
            {/*                />*/}
            {/*            </Dropdown.Trigger>*/}
            {/*        </Navbar.Item>*/}
            {/*        <Dropdown.Menu*/}
            {/*            aria-label="User menu actions"*/}
            {/*            color="primary"*/}
            {/*            onAction={(actionKey) => console.log({ actionKey })}*/}
            {/*        >*/}
            {/*            <Dropdown.Item key="profile" css={{ height: "$18" }}>*/}
            {/*                <Text b color="inherit" css={{ d: "flex" }}>*/}
            {/*                    Signed in as*/}
            {/*                </Text>*/}
            {/*                <Text b color="inherit" css={{ d: "flex" }}>*/}
            {/*                    zoey@example.com*/}
            {/*                </Text>*/}
            {/*            </Dropdown.Item>*/}
            {/*            <Dropdown.Item key="settings" withDivider>*/}
            {/*                My Settings*/}
            {/*            </Dropdown.Item>*/}
            {/*            <Dropdown.Item key="team_settings">Team Settings</Dropdown.Item>*/}
            {/*            <Dropdown.Item key="analytics" withDivider>*/}
            {/*                Analytics*/}
            {/*            </Dropdown.Item>*/}
            {/*            <Dropdown.Item key="system">System</Dropdown.Item>*/}
            {/*            <Dropdown.Item key="configurations">Configurations</Dropdown.Item>*/}
            {/*            <Dropdown.Item key="help_and_feedback" withDivider>*/}
            {/*                Help & Feedback*/}
            {/*            </Dropdown.Item>*/}
            {/*            <Dropdown.Item key="logout" withDivider color="error">*/}
            {/*                Log Out*/}
            {/*            </Dropdown.Item>*/}
            {/*        </Dropdown.Menu>*/}
            {/*    </Dropdown>*/}
            {/*</Navbar.Content>*/}
            <Navbar.Collapse>
                {collapseItems.map((item, index) => (
                    <Navbar.CollapseItem
                        key={index}
                        activeColor="primary"
                        isActive={index == active}
                    >
                        <Link
                            color="inherit"
                            css={{
                                minWidth: "100%",
                            }}
                            onClick={()=>{
                                changeActive(index, item.path)
                            }}
                        >
                            {item.title}
                        </Link>
                    </Navbar.CollapseItem>

                    // <Navbar.CollapseItem
                    //     key={item}
                    //     activeColor="secondary"
                    //     css={{
                    //         color: index === collapseItems.length - 1 ? "$error" : "",
                    //     }}
                    //     isActive={index === 2}
                    // >
                    //     <Link
                    //         color="inherit"
                    //         css={{
                    //             minWidth: "100%",
                    //         }}
                    //         href="#"
                    //     >
                    //         {item}
                    //     </Link>
                    // </Navbar.CollapseItem>
                ))}
            </Navbar.Collapse>
        </Navbar>
    );
}
