import http from "../../index";

export interface ServiceConsult {
    name: string;
    phone: string;
    type: "JOIN" | "SERVICE_MINI" | "SERVICE_MP" | "SERVICE_PC" | "";
}


export const serviceConsult = (appid: string, serviceConsultData: ServiceConsult) =>{
    return http<ServiceConsult>({
        url: `/ocean-weixin/wx-api/send/template/service/consult/${appid}`,
        method: "POST",
        data: serviceConsultData
    })
}
