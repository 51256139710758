import React from "react";
import {Svg} from "../styles/svg";
import {Box} from "../styles/box";

export const WechatMpIcon = () =>{
    return(
        <Box
            css={{
                width: 'auto',
                height: 'fit-content',
            }}
        >
            <svg className="icon" viewBox="0 0 1024 1024" version="1.1"
                 xmlns="http://www.w3.org/2000/svg" p-id="1187" width="32" height="32">
                <path
                    d="M504.1 389.3c-13.8 0-26.3 8.8-26.3 21.3s12.5 21.3 26.3 21.3c12.5 0 21.3-8.8 21.3-21.3s-8.8-21.3-21.3-21.3z m-120.3 42.6c12.5 0 21.3-8.8 21.3-21.3s-8.8-21.3-21.3-21.3-25.1 8.8-25.1 21.3 12.5 21.3 25.1 21.3z m211.8 3.8c-81.5 0-145.4 60.2-145.4 135.4 0 12.5 2.5 25.1 5 36.3h-16.3c-21.3 0-37.6-3.8-58.9-8.8l-60.2 30.1 17.5-51.4c-43.9-30.1-68.9-67.7-68.9-115.3 0-81.5 76.4-145.4 170.4-145.4 85.2 0 157.9 51.4 173 120.3-5-1.2-11.2-1.2-16.2-1.2z m-36.4 111.5c12.5 0 21.3-7.5 21.3-16.3s-8.8-17.5-21.3-17.5c-8.8 0-17.5 8.8-17.5 17.5 0 8.8 8.7 16.3 17.5 16.3z m94 0c12.5 0 21.3-7.5 21.3-16.3s-8.8-17.5-21.3-17.5c-8.8 0-16.3 8.8-16.3 17.5 0 8.8 7.5 16.3 16.3 16.3z m99 21.3c0 38.9-26.3 72.7-60.2 99l12.5 42.6-46.4-25.1c-17.5 3.8-35.1 7.5-51.4 7.5-81.5 0-145.4-55.1-145.4-124.1 0-67.7 63.9-124.1 145.4-124.1 76.6 0.1 145.5 56.5 145.5 124.2zM510.5 931.2c230.9 0 418.1-187.2 418.1-418.1S741.4 95 510.5 95 92.4 282.2 92.4 513.1s187.2 418.1 418.1 418.1z m0 22c-243 0-440.1-197-440.1-440.1S267.4 73 510.5 73s440.1 197 440.1 440.1-197.1 440.1-440.1 440.1z"
                    p-id="1188" fill="#34b93d"></path>
            </svg>
        </Box>
    )
}
