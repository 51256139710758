import http from "../../index";

export interface JoinUs {
    name: string;
    phone: string;
}

export const joinUs = (appid: string, joinUsData: JoinUs) =>{
    return http<JoinUs>({
        url: `/ocean-weixin/wx-api/send/template/join/us/${appid}`,
        method: "POST",
        data: joinUsData
    })
}
