// import {Card, Divider, Text, Tooltip} from '@nextui-org/react';
import {Card, Text, Tooltip} from '@nextui-org/react';
import React, {useState} from 'react';
// import {BoxIcon} from '../Icons/BoxIcon';
import {Box} from '../styles/box';
import {Flex} from '../styles/flex';
import {WechatMpIcon} from "../Icons/WechatMpIcon";
import {WechatMiniIcon} from "../Icons/WechatMiniIcon";
import QRCode from "../QRCode";
import {QRCodeMpIcon} from "../Icons/QRCodeMpIcon";
import {QRCodeMiniIcon} from "../Icons/QRCodeMiniIcon";

export const Features3 = () => {

   return (
      <>
         <Box
            css={{
               px: '$6',
               pb: '$14',
            }}
         >
            <Flex
               direction={'column'}
               justify={'center'}
               align={'center'}
               css={{
                  pt: '$20',
               }}
            >
               {/*<Text span css={{color: '$blue600'}}>*/}
               {/*   Awesome Feature*/}
               {/*</Text>*/}
               <Text h3>解决方案</Text>
               <Text
                  span
                  css={{
                     maxWidth: '800px',
                     color: '$accents8',
                     textAlign: 'center',
                  }}
               >
                  深入了解您的目标和要求，并提供个性化的开发方案，确保为您量身定制的产品达到最佳效果。选择予技谷，让我们一起打造您的定制化技术解决方案！
               </Text>
            </Flex>
            <Flex
               align={'center'}
               justify={'center'}
               wrap={'wrap'}
               css={{
                  gap: '1rem',
                  pt: '$14',
               }}
            >
                <Tooltip trigger="hover" placement="top" content={<QRCode> <QRCodeMpIcon/> </QRCode>}>
                    <Card isHoverable css={{mw: '500px'}}>
                      <Card.Body>
                             <Flex css={{gap: '0.5rem'}}>
                                {/*<BoxIcon />*/}
                                <WechatMpIcon></WechatMpIcon>
                                <Flex direction={'column'}>
                                   <Text h5
                                   css={{
                                       paddingTop:"4px",
                                   }}
                                   >微信公众号</Text>
                                   <Text span
                                         css={{
                                            color: '$accents8',
                                         }}
                                   >
                                      为您提供定制化的解决方案，无论您是想要建立一个企业公众号、服务号还是订阅号，我们都能满足您的需求，并提供全面的技术支持和优化建议。通过予技谷，您可以与用户进行互动、分享信息、推广产品，并建立稳固的用户群体。让我们一起开启微信公众号的新篇章！
                                   </Text>
                                </Flex>
                             </Flex>
                      </Card.Body>
                   </Card>
                </Tooltip>

                <Tooltip trigger="hover" placement="top" content={<QRCode> <QRCodeMiniIcon/> </QRCode>}>
                    <Card isHoverable css={{mw: '500px'}}>
                      <Card.Body>
                         <Flex css={{gap: '0.5rem'}}>
                            {/*<BoxIcon />*/}
                            <WechatMiniIcon/>
                            <Flex direction={'column'}>
                               <Text h5
                                     css={{
                                         paddingTop:"4px",
                                     }}
                               >微信小程序</Text>
                               <Text span
                                     css={{
                                        color: '$accents8',
                                     }}
                               >
                                  为您打造出色的移动应用体验。无论您是想要推出一款电商小程序、社交小程序还是服务小程序，我们都能为您提供定制化的开发解决方案。通过予技谷，您可以将您的品牌和服务直接带到用户的手机屏幕上，为用户提供便利的体验和丰富的功能。让我们一起开发创新的微信小程序！
                               </Text>
                            </Flex>
                         </Flex>
                      </Card.Body>
                   </Card>
                </Tooltip>
            </Flex>
            <Flex
               align={'center'}
               wrap={'wrap'}
               justify={'center'}
               css={{
                  gap: '1rem',
                  pt: '$8',
               }}
            >
               {/*<Card css={{mw: '1000px'}}>*/}
               {/*   <Card.Body>*/}
               {/*      <Flex css={{gap: '0.5rem'}}>*/}
               {/*         /!*<BoxIcon />*!/*/}
               {/*         <OfficeIcon/>*/}
               {/*         <Flex direction={'column'}>*/}
               {/*            <Text h5>公司官网</Text>*/}
               {/*            <Text span>*/}
               {/*               Lorem ipsum dolor sit amet, consectetur adipiscing*/}
               {/*               elit. Sed condimentum, nisl ut aliquam lacinia,*/}
               {/*               nisl nisl aliquet nisl, nec*/}
               {/*            </Text>*/}
               {/*         </Flex>*/}
               {/*      </Flex>*/}
               {/*   </Card.Body>*/}
               {/*</Card>*/}
               {/*<Card css={{mw: '500px'}}>*/}
               {/*   <Card.Body>*/}
               {/*      <Flex css={{gap: '0.5rem'}}>*/}
               {/*         <BoxIcon />*/}
               {/*         <Flex direction={'column'}>*/}
               {/*            <Text h5>Your Title</Text>*/}
               {/*            <Text span>*/}
               {/*               Lorem ipsum dolor sit amet, consectetur adipiscing*/}
               {/*               elit. Sed condimentum, nisl ut aliquam lacinia,*/}
               {/*               nisl nisl aliquet nisl, nec*/}
               {/*            </Text>*/}
               {/*         </Flex>*/}
               {/*      </Flex>*/}
               {/*   </Card.Body>*/}
               {/*</Card>*/}
            </Flex>
         </Box>

         {/*<Divider*/}
         {/*   css={{position: 'absolute', inset: '0p', left: '0', mt: '$5'}}*/}
         {/*/>*/}
      </>
   );
};
