import React from 'react';
import {Layout} from "../../components/INavbar/Layout";
import {Content} from "../../components/INavbar/Content";
import ReactFullPage from '@fullpage/react-fullpage';
import {Text, Card, Grid, Link, Container, Col} from "@nextui-org/react";
import {Hero} from "../../components/Hero";
import {Trusted} from "../../components/Trusted";
import {Features1} from "../../components/Features1";
import {Features2} from "../../components/Features2";
import {Features3} from "../../components/Features3";
import { Testimonials } from '../../components/tesminonials';
import {Statistics} from "../../components/statistics";
import {Plans} from "../../components/plans";
import {Faq} from "../../components/faq";
import {Trial} from "../../components/trial";
import {Footer} from "../../components/footer";

const  Home: React.FC = () => {
    return (
        <>
            <Hero></Hero>
            {/*<Trusted/>*/}
            {/*<Features1/>*/}
            {/*<Features2/>*/}
            <Statistics />

            <Features3/>
            <Testimonials />
            {/*<Plans />*/}
            <Faq />
            {/*<Trial />*/}
            <Footer />
        </>
    );
}

export default Home;
