import React, {Component, Suspense , lazy} from "react";
import KeepAlive from 'react-activation'
import {RouteObject, useRoutes} from "react-router-dom";

import Home from "../pages/Home";
import About from "../pages/About";

import {metaRoutersProps} from "./model";
import {Join} from "../pages/Join";


export const  rootRouter: metaRoutersProps[] =  [
    {
        path: '',
        element: <Home />,
        children:[]
    },{
        path: '/join',
        element: <Join />,
        children:[]
    }
    // ,{
    //     path: '/about',
    //     element: <About />,
    //     children:[]
    // }
]

const loading = (
    <div style={{ display: 'flex', height: '100vh' }}>
        loading ...
        {/*<Spin size='large' style={{ margin: 'auto' }} />*/}
    </div>
)

/** 懒加载处理 */
const syncRouter = (routes: Array<metaRoutersProps>): RouteObject[] => {
    const mRouteTable: RouteObject[] = []
    routes.forEach(item => {
        mRouteTable.push({
            path: item.path,
            element: (
                <Suspense fallback={loading}>
                    {item.meta?.keepAlive ? (
                        <KeepAlive id={item.path}>{item.element}</KeepAlive>
                    ) : (
                        item.element
                    )}
                </Suspense>
            ),
            children: item.children && syncRouter(item.children),
        })
    })
    return mRouteTable
}



const Router = () => {
    const routes = useRoutes(syncRouter(rootRouter))
    return routes
}

export default Router
