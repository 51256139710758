import {Button, Col, Divider, Input, Text} from '@nextui-org/react';
import React, {useState} from 'react';
import {CheckIcon} from '../Icons/CheckIcon';
import {Box} from '../styles/box';
import {Flex} from '../styles/flex';
import ModalQRCode from "../ModalQRCode";
import {MockIcon} from "../Icons/MockIcon";

export const Hero = () => {
   const [visible, setVisible] = useState(false)
   const changeModal = () =>{
       setVisible(!visible)
    }
   return (
      <>
          <ModalQRCode type="SERVICE_PC" changeModal={changeModal} initVisible={visible}></ModalQRCode>
         <Flex
            css={{
               'gap': '$3',
               'px': '$6',
               'flexDirection': 'column',
               'alignContent': 'center',
               'justifyContent': 'center',
               'alignItems': 'center',
               'width': '100%',
               '@sm': {
                  flexDirection: 'row',
                  mt: '$20',
               },
            }}
            justify={'center'}
         >
            <Box
               css={{
                  pt: '$13',
                  display: 'flex',
                  flexDirection: 'column',
                   alignContent: 'center',
                   justifyContent: 'center',
                   alignItems: 'center',
                   width: '100%',
                  gap: '$5',
               }}
            >
               <Box
                  css={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignContent: 'center',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                  }}
               >
                  <Text
                     h1
                     css={{
                        display: 'inline',
                     }}
                  >
                      <Col>
                          予技谷 - 定制化解决方案
                      </Col>
                  </Text>
                  <Text
                     h1
                     css={{
                        display: 'inline',
                     }}
                  >
                     {' '}
                  </Text>
                  <Text
                     h1

                     css={{
                         display: 'inline',

                         textGradient: "45deg, $blue600 -20%, $pink600 50%",
                     }}
                     color="primary"
                  >
                      满足您的需求
                  </Text>
               </Box>

               <Text
                  css={{
                     color: '$accents8',
                  }}
                  size={'$lg'}
                  span
               >
                   无论您需要软件开发、公司官网还是小程序和公众号开发，我们都能为您提供全方位的支持和服务。
               </Text>

               <Flex
                  css={{
                     gap: '$8',
                     pt: '$4',
                  }}
                  wrap={'wrap'}
               >
                  {/*<Input placeholder="手机号/邮箱" size="lg" />*/}
                  {/*<Button>提交联系方式</Button>*/}
                   <Button onClick={changeModal}>联系我们</Button>
               </Flex>
               <Flex
                  wrap={'wrap'}
                  css={{
                     'gap': '$8',
                     'py': '$7',
                     '@sm': {
                        py: '$4',
                     },
                  }}
               >
                  <Flex
                     css={{
                        color: '$accents7',
                        alignItems: 'center',
                     }}
                  >
                     <CheckIcon /> 免费咨询
                  </Flex>
                   <Flex
                       css={{
                           color: '$accents7',
                           alignItems: 'center',
                       }}
                   >
                       <CheckIcon /> 专业人员对接
                   </Flex>
                  <Flex
                     css={{
                        color: '$accents7',
                        alignItems: 'center',
                     }}
                  >
                     <CheckIcon /> 48小时内回复
                  </Flex>

               </Flex>
            </Box>
            {/*<Box*/}
            {/*   css={{*/}
            {/*      '& img': {*/}
            {/*         width: '775px',*/}
            {/*         objectFit: 'contain',*/}
            {/*      }*/}
            {/*   }}*/}
            {/*   style={{*/}
            {/*       padding: "80px 0"*/}
            {/*   }}*/}
            {/*>*/}
            {/*    /!*<MockIcon></MockIcon>*!/*/}
            {/*   <img src="/mock.png" />*/}
            {/*</Box>*/}
         </Flex>
         {/*<Divider*/}
         {/*   css={{position: 'absolute', inset: '0p', left: '0', mt: '$10'}}*/}
         {/*/>*/}
      </>
   );
};
